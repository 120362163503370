let assetsModule = false;

//let context = require.context('@/overrides/client/assets/', true, /\.js/);
let context = require.context('@/', true, /\/overrides\/client\/assets\/.*\.js/);

context.keys().forEach(key => {
    if ('./overrides/client/assets/assets.js' === key) {
        assetsModule = context(key);
    }
});

if ( ! assetsModule) {
    assetsModule = require("@/client/assets/assets.js");
}

export default assetsModule.default;
